<template>
  <div class="blog">
    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Resources</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5 mt-3"></p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row v-if="articles.length" cols="1" cols-md="3">
          <b-col v-for="(article, i) in articles" :key="i" class="post"  @click="downloadResource(article.document)">
            <h2 class="post-title">{{ article.title }}</h2>
            <p class="post-date">{{ article.description }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </div>
</template>

<script>
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'resources',
  mounted () {
    this.getResources()
  },
  data: () => ({
    articles: []
  }),
  methods: {
    downloadResource (url) {
      console.log(url)
      window.location.href = url
    },
    async getResources () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/resources/manuals/`)
        if (response.ok) {
          const text = await response.json()
          console.log(text.data)
          this.articles = text.data.map(a => ({
            title: a.title,
            description: a.description,
            document: a.document
          }))
        } else {
          const message = await response.json()
          console.error(message.error)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.post {
  & {
    border-radius: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 2rem;
    transition: background-color .2s ease;
    cursor: pointer;
  }
  &:hover {
    background-color: #dfe1e5;
  }
  &:hover > .post-thumbnail > .post-thumbnail-img {
    box-shadow: 6px 6px 12px transparentize(#000, 0.7);
  }
  &:hover > .post-thumbnail > .post-thumbnail-overlay {
    opacity: 0;
  }
  &:hover > .post-date {
    color: #05c2b9;
    opacity: 1;
  }
  > .post-thumbnail {
    position: relative;
    margin-bottom: 2rem;
  }
  > .post-title {
    margin-bottom: 1.5rem;
  }
  > .post-date {
    margin-bottom: 1rem;
    font-weight: 600;
    opacity: 0.3;
  }
}

.post-thumbnail {
  > .post-thumbnail-overlay {
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: .2s ease;
  }
}
</style>
